import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import AppProviders from './context';
import { BrowserRouter } from 'react-router-dom';



// ... imports etc.
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <AppProviders>
        <BrowserRouter>
            <App open={window.location.pathname} />
        </BrowserRouter>
    </AppProviders>
);
