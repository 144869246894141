
import React, { useState, useEffect } from 'react';
import * as authClient from '../utils/auth-client';
import { getAuth } from "firebase/auth";
import environmentSettings from '../configenv';
import { getAnalytics, logEvent } from 'firebase/analytics';



const AuthContext = React.createContext();

function AuthProvider(props) {
    const auth = getAuth();
    const jwtDecode = require('jwt-decode');
    const [authData, setAuthData] = useState(() => {
        let user = auth.currentUser;
        return { initializing: true, user, isAuthenticated: false };
    });

    useEffect(() => {
        const checkLoginStatus = async () => {
            try {
                const savedAuthData = JSON.parse(localStorage.getItem('authData'));
                if (savedAuthData !== null) {
                    if (!savedAuthData.expiration || Date.now() >= savedAuthData.expiration) {
                        logout();
                    } else {
                        setAuthData(savedAuthData);
                    }
                } else {
                    const credentials = await getRedirectResult();
                    if (credentials) {
                        finishAuthentication(credentials.idToken);
                    } else {
                        setAuthData(previousAuthData => {return {...previousAuthData, initializing: false}})
                    }
                    
                }
            } catch (error) {
                setAuthData({initializing: false, isAuthenticated: false});
            }
        };
        checkLoginStatus();
    }, []);

    const finishAuthentication = async (idToken) => {
        const accessToken = await getAccessToken(idToken);
        try {
            const decodedAccessToken = jwtDecode(accessToken);
            const completeAuthData = {
                initializing: false,
                isAuthenticated: true,
                user: {
                    id: decodedAccessToken.nameid,
                    displayName: decodedAccessToken.unique_name,
                    email: decodedAccessToken.email,
                    role: decodedAccessToken.role
                },
                expiration: decodedAccessToken.exp * 1000,
                tokens: {idToken, accessToken}
            };
            window.localStorage.setItem('authData', JSON.stringify(completeAuthData));
            setAuthData(completeAuthData);
            const analytics = getAnalytics();
            logEvent(analytics, 'login_page');
        } catch (error) {
        }
    }

    const login = () => {
        setAuthData(previous => {return {...previous, initializing: true}});
        if (environmentSettings.env === 'local') {
            authClient.localLogin().then(async (credentials) => {
                return await finishAuthentication(credentials.user.accessToken);
            }).catch((error) => {
                console.error(error);
                setAuthData(prevAuthData => {return {...prevAuthData, initializing: false}});
            });
        } else {
            return authClient.login();
        }
    };
    const logout = () => {
        window.localStorage.removeItem('tokenMessage');
        window.localStorage.removeItem('authData');
        authClient.logout();
        window.location = '/login';
    };
    const getRedirectResult = () => authClient.goRedirect();
    const getAccessToken = idToken => authClient.getAccessToken(idToken);



    return (
        <AuthContext.Provider
            value={{
                authData,
                setAuthData,
                login,
                logout,
                getRedirectResult,
                getAccessToken,

            }}
            {...props}
        />
    );
}

function useAuth() {
    const context = React.useContext(AuthContext);

    if (context === undefined) {
        throw new Error('useAuth must be used within AuthProvider');
    }
    return context;
}

export { AuthProvider, useAuth };