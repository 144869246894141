import { createTheme } from '@mui/material/styles';
import themeDefaultOptions from './theme-default-options';
import logo from '../assets/logo_internacional.webp';

const themeec = createTheme({
    ...themeDefaultOptions,
    logo,
    logoHeight: '64px',
    palette: {
        primary: {
            main: '#F28B00',
        },
        secondary: {
            main: '#E53E3E',
        },
        success: {
            main: '#43D123',
        },
        error: {
            main: '#ED0505'
        },
        default: {
            main: '#888e93',
        },
        background: {
            default: '#ecedec',
        },
        drawer: {
            topbar: '#F28B00',
            main: 'black',
            menuIcon: '#F28B00',
            selectedLink: 'white',
            colorText: 'white',
            selectedText: '#F28B00',
            selectedIcon: '#F28B00',
        },
        post: {
            like: '#747473',
        },
        notifica: {
            main: 'black',
            texto: 'white',
        },
        etiquetas: {
            texto: '#61A9F7',
        },
        iconospost: {
            color: '#888e93',
        },

    },
    overrides: {
        MuiListItemIcon: {
            root: {
                minWidth: 35,
            },
        },
    },
});

//New shadows for the theme
themeec.shadows[24] =
    '0 10px 15px -3px rgba(0,0,0,.1),0 4px 6px -2px rgba(0,0,0,.05)';

export default themeec;
