import { createTheme } from '@mui/material/styles';
import themeDefaultOptions from './theme-default-options';
import logo from '../assets/logo4.png';

const themebb = createTheme({
    ...themeDefaultOptions,
    logo,
    logoHeight: '64px',
    palette: {
        primary: {
            main: '#22a6f5',
        },
        secondary: {
            main: '#aca9aa',
        },
        success: {
            main: '#43D123',
        },
        error: {
            main: '#ED0505'
        },
        default: {
            main: '#FFFFFF',
        },
        background: {
            default: '#ecedec',
        },
        drawer: {
            topbar: '#FFF',
            main: '#FFFFFF',
            menuIcon: '#22a6f5',
            selectedLink: '#61A3fB',
            colorText: '#828282',
            selectedText: '#FFFFFF',
            selectedIcon: '#FFFFFF',
        },
        post: {
            like: '#747473',
        },
        notifica: {
            main: '#FFF',
            texto: '#828282',
        },
        etiquetas: {
            texto: '#61A9F7',
        },
        iconospost: {
            color: '#22a6f5',
        },


    },
    overrides: {
        MuiListItemIcon: {
            root: {
                minWidth: 35,
            },
        },
    },
});

//New shadows for the theme
themebb.shadows[24] =
    '0 10px 15px -3px rgba(0,0,0,.1),0 4px 6px -2px rgba(0,0,0,.05)';

export default themebb;