import environmentSettings from "./configenv";


var firebaseKeys = {
  apiKey: 'AIzaSyCrW5ilbMfFXzl-HYZkiXDfKmL5wVtgqjo',
  authDomain: environmentSettings.authDomain,
  databaseURL: 'https://conectados-61b81.firebaseio.com',
  projectId: 'conectados-61b81',
  storageBucket: "conectados-61b81.appspot.com",
  messagingSenderId: "501358800",
  appId: "1:501358800:web:974e65c090fce524247ed5",
  measurementId: "G-SVXMSJHSGL",
  authorization_user_agent: 'WEBVIEW',
};

export default firebaseKeys; 
