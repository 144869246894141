import React from 'react';
import { Grid, CircularProgress } from '@mui/material';

function FullPageSpinner() {
    return (
        <Grid
            container
            style={{ height: '100vh' }}
            alignContent="center"
            alignItems="center"
            justifyContent="center"
        >
            <CircularProgress color='grey' />
        </Grid>
    );
}

export default FullPageSpinner;