import { createTheme } from '@mui/material/styles';
import themeDefaultOptions from './theme-default-options';
import logo from '../assets/logo_interbanco.png';

const themeib = createTheme({
    ...themeDefaultOptions,
    logo,
    logoHeight: '50px',
    palette: {
        primary: {
            main: '#00539B',
        },
        secondary: {
            main: '#009ADE',
        },
        success: {
            main: '#43D123',
        },
        error: {
            main: '#ED0505'
        },
        default: {
            main: '#00539B',
        },
        background: {
            default: '#FFFFFF',
        },
        drawer: {
            topbar: '#00539B',
            main: '#002E6D',
            menuIcon: 'rgb(234, 242, 242)',
            selectedLink: 'rgb(225, 114, 0)',
            colorText: 'rgb(234, 242, 242)',
            selectedText: 'rgb(234, 242, 242)',
            selectedIcon: 'rgb(234, 242, 242)',
        },
        post: {
            like: '#EF4035',
        },
        notifica: {
            main: '#002E6D',
            texto: 'rgb(234, 242, 242)',
        },
        etiquetas: {
            texto: '#61A9F7',
        },
        iconospost: {
            color: '#00539B',
        },


    },
    overrides: {
        MuiListItemIcon: {
            root: {
                minWidth: 35,
            },
        },
    },
});

//New shadows for the theme
themeib.shadows[24] =
    '0 10px 15px -3px rgba(0,0,0,.1),0 4px 6px -2px rgba(0,0,0,.05)';

export default themeib;