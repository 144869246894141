import { createTheme } from '@mui/material/styles';
import themeDefaultOptions from './theme-default-options';
import logo from '../assets/logo_exterior.svg';

const themeex = createTheme({
    ...themeDefaultOptions,
    logo,
    logoHeight: '30px',
    palette: {
        primary: {
            main: '#2970CA',
        },
        secondary: {
            main: '#0077C8',
        },
        default: {
            main: '#2970CA',
        },
        success: {
            main: '#43D123',
        },
        error: {
            main: '#ED0505'
        },
        background: {
            default: '#ecedec',
        },
        drawer: {
            topbar: '#0075C9',
            main: '#0075C9',
            menuIcon: '#FFF',
            selectedLink: '#FFF',
            colorText: '#FFF',
            selectedText: '#F58300',
            selectedIcon: '#F58300',
        },
        post: {
            like: '#747473',
        },
        notifica: {
            main: '#0075C9',
            texto: 'rgb(234, 242, 242)',
        },
        etiquetas: {
            texto: '#61A9F7',
        },
        iconospost: {
            color: '#EC8213',
        },

    },
    overrides: {
        MuiListItemIcon: {
            root: {
                minWidth: 35,
            },
        },
    },
});

//New shadows for the theme
themeex.shadows[24] =
    '0 10px 15px -3px rgba(0,0,0,.1),0 4px 6px -2px rgba(0,0,0,.05)';

export default themeex;
