import branch from "./branch";
const settings = {
  dev: {
    apiEndPoint: 'https://apiawsdev.if-tech.com/services',
    accesTokenEndPoint: 'https://apiawsdev.if-tech.com',
    bucketName: 'conectados-infra-dev-imagen-118810329408-us-east-1',
    envPath: 'https://dev.if-tech.com',
    authDomain: 'dev.if-tech.com'
  },
  local: {
    apiEndPoint: 'https://apiawsdev.if-tech.com/services',
    accesTokenEndPoint: 'https://apiawsdev.if-tech.com',
    bucketName: 'conectados-infra-dev-imagen-118810329408-us-east-1',
    envPath: 'http://localhost:3000',
    authDomain: 'dev.if-tech.com'
  },
  prod: {
    apiEndPoint: 'https://apiawsconectados.if-tech.com/services',
    accesTokenEndPoint: 'https://apiawsconectados.if-tech.com',
    bucketName: 'conectados-infra-master-imagen-573179928547-us-east-1',
    envPath: 'https://conectados.if-tech.com',
    authDomain: 'conectados.if-tech.com'
  }
};
var environmentSettings = {...settings[branch], env: branch};
  
export default environmentSettings; 
  