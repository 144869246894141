const themeDefaultOptions = {
    components: {
        MuiSkeleton: {
            defaultProps: {
                animation: 'wave'
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'capitalize'
                }
            }
        }
    }
};

export default themeDefaultOptions;