import firebaseKeys from '../config';
import {
    OAuthProvider,
    getAuth,
    signInWithRedirect,
    getRedirectResult,
    signOut,
    browserSessionPersistence,
    setPersistence,
    signInWithPopup,
} from "firebase/auth";
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import environmentSettings from '../configenv';


const firebaseConfig = firebaseKeys;
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function initService() {
    const isToken = window.localStorage.getItem('tokenMessage');

    if ("serviceWorker" in navigator && !isToken) {
        navigator.serviceWorker
            .register("../firebase-messaging-sw.js")
            .then(function (registration) {
                console.log("Registration successful, scope is:", registration.scope);
            })
            .catch(function (err) {
                console.log("Service worker registration failed, error:", err);
            });
    }
}

const login = async () => {
    const provider = new OAuthProvider('microsoft.com');
    provider.setCustomParameters({
        prompt: 'select_account',
        tenant: 'common',
    });

    initService();
    logEvent(analytics, 'login');

    const auth = getAuth();
    setPersistence(auth, browserSessionPersistence)
        .then(() => {
            signInWithRedirect(auth, provider);
        }).catch((error) => {
            console.log(error.message);
        });


};

const localLogin = () => new Promise((resolve, reject) => {
    const provider = new OAuthProvider('microsoft.com');
    provider.setCustomParameters({
        prompt: 'consent',
        tenant: 'common',
    });
    initService();
    const auth = getAuth();
    setPersistence(auth, browserSessionPersistence)
        .then(async () => {
            const credentials = await signInWithPopup(auth, provider);
            resolve(credentials);
        }).catch((error) => {
            console.log(error.message);
            reject(error);
        });
});

const logout = () => {
    const auth = getAuth();
    signOut(auth).then(() => {
        console.log('ha salido con exito');
    }).catch((error) => {
        console.log(error);
    });

};

async function goRedirect() {
    const auth = getAuth();
    const result = await getRedirectResult(auth)
        .then((view) => {

            const credential = OAuthProvider.credentialFromResult(view);
            return credential;
        })
        .catch((error) => {
            // Handle error.
        });
    if (result) {
        return result;
    } else {
        return null;
    }


}

async function getAccessToken(idToken) {
    const valreq = environmentSettings.accesTokenEndPoint+"/security/validTenant";
    const accessToken = await fetch(valreq, {
        method: 'POST',
        cache: 'no-cache',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ value: idToken }),
    })
        .then(response => {
            if (response.status !== 200) {
                throw new Error(response.status);
            }
            const resjson = response.json();
            return resjson;
        })
        .catch(error => {
            console.log(error);
            logout();
        });
    if (!accessToken) {
        return;
    }


    return accessToken.token;
}

export { login, logout, goRedirect, getAccessToken, localLogin };