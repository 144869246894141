import React, { useEffect, useState } from 'react';
import {
    Route,
    Redirect,
    Switch,
    useLocation,
    useHistory,
} from 'react-router-dom';
import { useAuth } from './context/auth-context';
import FullPageSpinner from './components/FullPageSpinner';
import './App.scss';
import { Suspense } from 'react';
import { CssBaseline } from '@mui/material';

const loadAuthenticatedApp = () => import('./AuthenticatedApp');
const AuthenticatedApp = React.lazy(loadAuthenticatedApp);
const UnauthenticatedApp = React.lazy(() => import('./UnauthenticatedApp'));
const PrivacyPolicy = React.lazy(() => import('./screens/PrivacyPolicy'));
const NoSupport = React.lazy(() => import('./IeNotSupported'));

const publicRoutes = [
    '/privacy',
    '/nosupport'
];

const PublicRoute = ({ component: Component, restricted, navallowed, isAuthenticated, ...rest }) => {
    
    return (
        navallowed ? (
            <Route {...rest} render={props => (
                <Redirect to="/nosupport" />
            )} />
        ) : (
            <Route {...rest} render={props => (
                isAuthenticated && restricted ?
                <Redirect to="/home" />
                : <Component {...props} />
            )} />)
            
        );
    };
    
    
function App(props) {
    const {
        authData,
        authData: { isAuthenticated, initializing, user },
    } = useAuth();
    const location = useLocation();
    const history = useHistory();
    const [all, setAll] = useState(false);

    useEffect(() => {
        function isIE() {
            const ua = window.navigator.userAgent; //Check the userAgent property of the window.navigator object
            const msie = ua.indexOf('MSIE '); // IE 10 or older
            const trident = ua.indexOf('Trident/'); //IE 11
            
            return (msie > 0 || trident > 0);
        }
        if (!initializing && !isAuthenticated && publicRoutes.indexOf(location.pathname) < 0) {
            history.push('/login');
        }
        
        
        setAll(isIE());
        loadAuthenticatedApp();
    }, [authData]);
    
    return (
        <Suspense fallback={<FullPageSpinner />}>
            <CssBaseline/>
            {(isAuthenticated && !initializing) && (
                <AuthenticatedApp/>
            )}
            <Switch>
                <PublicRoute restricted={true}
                    component={UnauthenticatedApp}
                    navallowed={all}
                    isAuthenticated={isAuthenticated}
                    path="/" exact

                />
                <PublicRoute restricted={true}
                    component={UnauthenticatedApp}
                    navallowed={all}
                    isAuthenticated={isAuthenticated}
                    path="/login" exact
                />
                <Route path="/privacy" exact>
                    <PrivacyPolicy/>
                </Route>
                
                <PublicRoute restricted={true}
                    component={NoSupport}
                    isAuthenticated={isAuthenticated}
                    path="/nosupport" exact
                />
                <Redirect from='/singlePost/:idPost' to='/post/:idPost'/>
                <Redirect from='/home/:idPost' to='/post/:idPost'/>
            </Switch>
        </Suspense>
    );
}

export default App;