import React, { useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { themeCreator } from './theme-base';
import { AuthProvider } from './auth-context';

export const ThemeContext = React.createContext((themeName) => { });

const ThemeProv = ({ children }) => {
    const [tempresa, setTempresa] = useState('themewt');

    const theme = themeCreator(tempresa);

    const setThemeName = (themeName) => {
        setTempresa(themeName);
    }

    return (
        <ThemeContext.Provider value={setThemeName}>
            <ThemeProvider theme={theme}>
                <AuthProvider>{children}</AuthProvider>
            </ThemeProvider>
        </ThemeContext.Provider>
    );
}



export default ThemeProv;