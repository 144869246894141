import React from 'react';
import ThemeProv from './theme-provider';


function AppProviders({ children }) {
    return (
        <ThemeProv>{children}</ThemeProv>
    );
}

export default AppProviders;
