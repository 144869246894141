import themegt from './theme-gt';
import themeec from './theme-ec';
import themewt from './theme-wt';
import themeib from './theme-ib';
import themeex from './theme-ex';
import themebb from './theme-bb';
import themedgc from './theme-dgc';

export function themeCreator(theme) {
    return themeMap[theme];
}

const themeMap = {
    themegt,
    themeec,
    themewt,
    themeib,
    themeex,
    themebb,
    themedgc,
};