import { createTheme } from '@mui/material/styles';
import themeDefaultOptions from './theme-default-options';
import logo from '../assets/logo_interbanco.png';

const themegt = createTheme({
    ...themeDefaultOptions,
    logo,
    logoHeight: '50px',
    palette: {
        primary: {
            main: '#3275B9',
        },
        secondary: {
            main: '#be6413',
        },
        success: {
            main: '#43D123',
        },
        error: {
            main: '#ED0505'
        },
        default: {
            main: '#3275B9',
        },
        background: {
            default: '#F3F7F9',
        },
        drawer: {
            main: '#042EA4',
            menuIcon: 'rgb(190,100, 19)',
            selectedLink: '#758cd4',
            colorText: 'rgb(234, 242, 242)',
            selectedText: 'rgb(234, 242, 242)',
            selectedIcon: 'rgb(190,100, 19)',
        },
        post: {
            like: '#042EA4',
        },
        notifica: {
            main: '#3275B9',
            texto: 'rgb(234, 242, 242)',
        },
        etiquetas: {
            texto: '#61A9F7',
        },
        iconospost: {
            color: '#3275B9',
        },
    },
    overrides: {
        MuiListItemIcon: {
            root: {
                minWidth: 35,
            },
        },
    },
});

//New shadows for the theme
themegt.shadows[24] =
    '0 10px 15px -3px rgba(0,0,0,.1),0 4px 6px -2px rgba(0,0,0,.05)';

export default themegt;