import { createTheme } from '@mui/material/styles';
import themeDefaultOptions from './theme-default-options';

const themewt = createTheme({
    ...themeDefaultOptions,
    palette: {
        primary: {
            main: '#FFFFFF',
        },
        secondary: {
            main: '#FFFFFF',
        },
        success: {
            main: '#FFFFFF',
        },
        error: {
            main: '#FFFFFF'  
        },
        default: {
            main: '#FFFFFF',
        },
        background: {
            default: '##FFFFFF',
        },
        drawer: {
            main: '#FFFFFF',
            menuIcon: 'rgb(255, 255, 255)',
            selectedLink: '#FFFFFF',
            colorText: 'rgb(255, 255, 255)',
            selectedText: 'rgb(255, 255, 255)',
            selectedIcon: 'rgb(255, 255, 255)',
        },
        post: {
            like: '#FFFFFF',
        },
        notifica: {
            main: '#FFFFFF',
            texto: 'rgb(255, 255, 255)',
        },
        etiquetas: {
            texto: '#FFFFFF',
        },
        iconospost: {
            color: '#FFFFFF',
        },

    },
    overrides: {
        MuiListItemIcon: {
            root: {
                minWidth: 35,
            },
        },
    },
});

//New shadows for the theme
themewt.shadows[24] =
    '0 10px 15px -3px rgba(0,0,0,.1),0 4px 6px -2px rgba(0,0,0,.05)';

export default themewt;